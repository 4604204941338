import type { VerticalNavItems } from '@/@layouts/types'
import {
  CERTIFICATE_ASSETS,
  CERTIFICATE_RECIPIENT,
  COMPONENT_LIST,
  COURSE_LIST,
  LESSONS_LIST,
  MENTOR_LIST,
  USER_ENROLLMENT,
  USER_LIST,
} from '@/router/types'
import isProduction from '@/utils/is-production'

export const navigations = [
  {
    heading: 'LEARNING MANAGEMENT',
    disabledInProduction: true,
  },
  {
    title: 'Course Management',
    disabledInProduction: false,
    icon: { icon: 'mdi-list-box-outline' },
    children: [
      {
        title: 'Course List',
        to: { name: COURSE_LIST },
      },
      {
        title: 'Lessons List',
        to: { name: LESSONS_LIST },
      },
      {
        title: 'Component List',
        to: { name: COMPONENT_LIST },
      },
    ],
  },
  {
    title: 'User Management',
    disabledInProduction: false,
    icon: { icon: 'mdi-person' },
    children: [
      {
        title: 'User List',
        to: { name: USER_LIST },
      },
      {
        title: 'User Enrollment',
        to: { name: USER_ENROLLMENT },
      },
    ],
  },
  {
    title: 'Certificate',
    disabledInProduction: false,
    icon: { icon: 'mdi-clipboard-outline' },
    children: [
      {
        title: 'Certificate Assets',
        to: { name: CERTIFICATE_ASSETS },
      },
      {
        title: 'Certificate Recipient',
        to: { name: CERTIFICATE_RECIPIENT },
      },
    ],
  },
  {
    title: 'Mentor Management',
    to: { name: MENTOR_LIST },
    icon: { icon: 'mdi-account-cog' },
  },
]

const routes = [] as VerticalNavItems
if (isProduction) {
  navigations.forEach((navigation: any) => {
    if (!navigation.disabledInProduction) {
      if (navigation.children) {
        const children = navigation.children.filter((child: any) => !child.disabledInProduction)
        routes.push({ ...navigation, children })
      } else {
        routes.push(navigation)
      }
    }
  })
} else {
  routes.push(...navigations)
}

export default routes
