<script setup lang="ts">
import { getAnalytics } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'
import app from './firebase'
import { useThemeConfig } from '@core/composable/useThemeConfig'
const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  isAppRtl,
} = useThemeConfig()

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()

// Initialize firebase analytics and performance
getAnalytics(app)
getPerformance(app)
</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <VApp>
      <VMain>
        <RouterView />
      </VMain>
    </VApp>
  </VLocaleProvider>
</template>
