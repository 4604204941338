import type { RouteLocationNormalized } from 'vue-router'
import { ALL, HOME } from './types'
import isProduction from '@/utils/is-production'
import { navigations } from '@/navigation/vertical'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// TODO: userData not already yet, check just accessToken only
// export const isUserLoggedIn = () => !!(localStorage.getItem('userData') && localStorage.getItem('accessToken'))
export const isUserLoggedIn = () => !!(localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken'))

export const getTokenStorage = () => {
  if (isUserLoggedIn()) {
    return localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')
  }

  return null
}

export const getFirstRoute = (options?: { category: string }) => {
  let routes = navigations

  if (options?.category) {
    const index = routes.findIndex((route: any) => route.heading === options.category)

    routes = routes.slice(index)
  }

  const firstRoute = routes.find((route: any) => {
    if (isProduction) {
      return !route.disabledInProduction && route.title
    }

    return route.title
  }) as any

  return firstRoute.to || firstRoute.children[0].to || { name: HOME }
}

export const setHistoryState = (state: RouteLocationNormalized) => {
  if (state.name !== HOME && state.name !== ALL) {
    window.history.pushState(
      {
        name: state.name,
        query: state.query,
        params: state.params,
      },
      '',
    )
  }
}

export const getHistoryState = () => {
  return {
    name: window.history.state?.name || undefined,
    query: window.history.state?.query || undefined,
    params: window.history.state?.params || undefined,
  }
}
