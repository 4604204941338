import {
  AuthApi as AuthApiV1,
  Configuration as ConfigurationV1,
  ContentsApi as ContentsApiV1,
  LearningContentApi as LearningContentApiV1,
  LessonsApi as LessonsApiV1,
  OrdersApi as OrdersApiV1,
  ProductsApi as ProductsApiV1,
  SubscriptionsApi as SubscriptionsApiV1,
  UsersApi as UsersApiV1,
} from '@kunciehub/backoffice-api-client'
import {
  BackofficeCertificateAssetApi,
  BackofficeCoursesApi,
  BackofficeEventsApi,
  ComponentsApi,
  Configuration,
  CoursesApi,
  DocumentsApi,
  EnrollmentsApi,
  LessonsApi,
  LiveclassApi,
  MeApi,
  MentorsApi,
  QuizzesApi,
  ReservationApi,
  ReservationsApi,
  UsersApi,
  UtilsApi,
  VideosApi,
} from '@kunciehub/api-v2-web-js-client'
import type { ConfigurationParameters } from '@kunciehub/api-v2-web-js-client'
import type { App } from 'vue'
import axiosIns from './axios'

const basePathV1 = import.meta.env.VITE_API_URL_V1
const basePath = import.meta.env.VITE_API_URL

const configParams = (): ConfigurationParameters => {
  return {
    baseOptions: {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Login-Type': 'web',
      },
    },
  }
}

const ConfigAPIV1 = new ConfigurationV1(configParams())
const ConfigAPI = new Configuration(configParams())

export const setupAPIV1 = () => ({
  authUser: new AuthApiV1(ConfigAPIV1, basePathV1, axiosIns),
  learningContent: new LearningContentApiV1(ConfigAPIV1, basePathV1, axiosIns),
  lessons: new LessonsApiV1(ConfigAPIV1, basePathV1, axiosIns),
  content: new ContentsApiV1(ConfigAPIV1, basePathV1, axiosIns),
  orders: new OrdersApiV1(ConfigAPIV1, basePathV1, axiosIns),
  orderSubscription: new SubscriptionsApiV1(ConfigAPIV1, basePathV1, axiosIns),
  products: new ProductsApiV1(ConfigAPIV1, basePathV1, axiosIns),
  userData: new UsersApiV1(ConfigAPIV1, basePathV1, axiosIns),
})

export const setupAPI = () => ({
  assets: new BackofficeCertificateAssetApi(ConfigAPI, basePath, axiosIns),
  backofficeMe: new MeApi(ConfigAPI, basePath, axiosIns),
  backofficeCourses: new BackofficeCoursesApi(ConfigAPI, basePath, axiosIns),
  components: new ComponentsApi(ConfigAPI, basePath, axiosIns),
  courses: new CoursesApi(ConfigAPI, basePath, axiosIns),
  documents: new DocumentsApi(ConfigAPI, basePath, axiosIns),
  enrollments: new EnrollmentsApi(ConfigAPI, basePath, axiosIns),
  events: new BackofficeEventsApi(ConfigAPI, basePath, axiosIns),
  lessons: new LessonsApi(ConfigAPI, basePath, axiosIns),
  mentors: new MentorsApi(ConfigAPI, basePath, axiosIns),
  quizzes: new QuizzesApi(ConfigAPI, basePath, axiosIns),
  reservation: new ReservationApi(ConfigAPI, basePath, axiosIns),
  reservations: new ReservationsApi(ConfigAPI, basePath, axiosIns),
  users: new UsersApi(ConfigAPI, basePath, axiosIns),
  utils: new UtilsApi(ConfigAPI, basePath, axiosIns),
  videos: new VideosApi(ConfigAPI, basePath, axiosIns),
  webinars: new LiveclassApi(ConfigAPI, basePath, axiosIns),
})

export type ApiModules = ReturnType<typeof setupAPI>

export default {
  install: (app: App) => {
    const modules = setupAPI()
    app.config.globalProperties.$api = modules
  },
}
