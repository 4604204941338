// root routes
export const HOME = 'index'
export const ALL = 'all'
export const LOGIN = 'login'
export const SSO_LOGIN = 'v2-login'
export const SSO_AUTH = 'v2-auth'

// commercial routes
export const ORDER_MANAGEMENT_LIST = 'commercial-order-management'
export const ORDER_MANAGEMENT_DETAIL = 'commercial-order-management-id'

export const ORDER_SUBSCRIPTION_LIST = 'commercial-order-subscription'
export const ORDER_SUBSCRIPTION_DETAIL = 'commercial-order-subscription-id'
export const ORDER_SUBSCRIPTION_ASSIGN_CUSTOMER = 'commercial-order-subscription-assign-customer'

export const PRODUCT_MANAGEMENT_LIST = 'commercial-product-management'
export const PRODUCT_MANAGEMENT_CREATE = 'commercial-product-management-create'

// learning management routes
export const CATEGORY_0_LIST = 'learning-management-category-0'
export const CATEGORY_0_DETAIL = 'learning-management-category-0-id'
export const CATEGORY_0_CREATE = 'learning-management-category-0-create'
export const CATEGORY_0_EDIT = 'learning-management-category-0-edit-id'

export const CATEGORY_1_LIST = 'learning-management-category-1'
export const CATEGORY_1_DETAIL = 'learning-management-category-1-id'
export const CATEGORY_1_CREATE = 'learning-management-category-1-create'
export const CATEGORY_1_EDIT = 'learning-management-category-1-edit-id'

export const COMPONENT_LIST = 'learning-management-component'
export const COMPONENT_DETAIL = 'learning-management-component-type-id'
export const COMPONENT_CREATE = 'learning-management-component-create'
export const COMPONENT_EDIT = 'learning-management-component-edit-type-id'

export const COURSE_LIST = 'learning-management-course'
export const COURSE_DETAIL = 'learning-management-course-id'
export const COURSE_CREATE = 'learning-management-course-create'
export const COURSE_EDIT = 'learning-management-course-edit-id'
export const COURSE_ASSIGN = 'learning-management-course-assign'

export const LESSONS_LIST = 'learning-management-lessons'
export const LESSONS_DETAIL = 'learning-management-lessons-id'
export const LESSONS_CREATE = 'learning-management-lessons-create'
export const LESSONS_EDIT = 'learning-management-lessons-edit-id'

export const MENTOR_LIST = 'learning-management-mentor'
export const MENTOR_DETAIL = 'learning-management-mentor-id'
export const MENTOR_CREATE = 'learning-management-mentor-create'
export const MENTOR_EDIT = 'learning-management-mentor-edit-id'

// User Management Route

export const USER_ENROLLMENT = 'user-management-user-enrollment'
export const USER_ENROLLMENT_CREATE = 'user-management-user-enrollment-create'
export const USER_ENROLLMENT_DETAIL = 'user-management-user-enrollment-id'
export const USER_LIST = 'user-management-user-list'
export const USER_LIST_DETAIL = 'user-management-user-list-id'

// Certificate
export const CERTIFICATE_ASSETS = 'certificate-certificate-assets'
export const CERTIFICATE_ASSETS_CREATE = 'certificate-certificate-assets-create'

export const CERTIFICATE_RECIPIENT = 'certificate-certificate-recipient'
export const CERTIFICATE_RECIPIENT_DETAIL = 'certificate-certificate-recipient-id'
export const CERTIFICATE_RECIPIENT_CREATE = 'certificate-certificate-recipient-create'
