import axios from 'axios'
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getTokenStorage, isUserLoggedIn } from '../router/utils'

const axiosIns: AxiosInstance = axios.create()

axiosIns.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers = config.headers ?? {}
  if (isUserLoggedIn() && config.headers && !config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${getTokenStorage()}`
  }

  return config
})

axiosIns.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const errorResponse = {
      response: error.response || 0,
      status: error.response?.status || 0,
      message: error.message
    }

    if (errorResponse.status === 401) {
      if (localStorage.getItem('accessToken')) {
        localStorage.removeItem('accessToken')
      }

      if (sessionStorage.getItem('accessToken')) {
        sessionStorage.removeItem('accessToken')
      }

      window.location.reload()
    }

    return Promise.reject(errorResponse)
  },
)

export default axiosIns
